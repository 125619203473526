// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from "lodash";
import type { Reducer } from "redux";

import type { Dashboard } from "../Dashboard";

import { UserLogoutType, UserProfileType } from "../../User/redux/types";
import {
	DashboardCurrentType,
	DashboardType,
	DashboardWidgetType,
	DashboardWidgetsType,
	DashboardsType,
} from "./types";

import { reduceAll, reduceOne } from "../../libs/redux/reducer";

const sort = ["ordre"];

const isDashboard = (obj): obj is Dashboard => _.isPlainObject(obj);

const updateCurrent = (
	olData: Dashboard[],
	newData: Dashboard[],
	current: Partial<Dashboard> | number,
) => {
	const lastIndex = _.findIndex(olData, (d, i) =>
		isDashboard(current)
			? current.identifiant === d.identifiant
			: current === i,
	);

	const newIndex = _.findIndex(newData, (d, i) =>
		isDashboard(current)
			? current.identifiant === d.identifiant
			: current === i,
	);

	return _.map(newData, (d, i: number) => ({
		...d,
		current: (newIndex >= 0 ? newIndex : lastIndex - 1) === i,
	}));
};

const patchCurrent = (state, patcher: (data: Dashboard) => Dashboard) => {
	const current = _.find(state, { current: true });

	return reduceAll(
		_.filter(
			state,
			(dashboard) => dashboard.identifiant !== current.identifiant,
		),
		patcher(current),
		sort,
	);
};

// eslint-disable-next-line default-param-last, @typescript-eslint/default-param-last
export const dashboardsReducer: Reducer = (state, action) => {
	switch (action.type) {
		case DashboardsType:
			return reduceAll(state, action.dashboards, sort);

		case DashboardType:
			return updateCurrent(
				state,
				reduceOne(
					state,
					action.dashboard,
					(dashboard) => dashboard.identifiant !== action.identifiant,
					sort,
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
				),
				(action.dashboard as Dashboard) || {
					identifiant: action.identifiant as number,
				},
			);

		case DashboardCurrentType:
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			return updateCurrent(state, state, action.dashboard);

		case DashboardWidgetsType:
			return patchCurrent(state, (dashboard) => dashboard);

		case DashboardWidgetType:
			return patchCurrent(state, (dashboard) => dashboard);

		case UserLogoutType:
		case UserProfileType:
			return [];

		default:
			return state || [];
	}
};
