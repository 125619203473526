// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from "react";

export type ApplicationLauncherContextProps = {
	/** */
	open: boolean;

	/** */
	setOpen?: React.Dispatch<React.SetStateAction<boolean>>;

	/** */
	visible: boolean;

	/** */
	setVisible?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ApplicationLauncherContext =
	React.createContext<ApplicationLauncherContextProps>({
		open: false,
		setOpen: null,

		visible: true,
		setVisible: null,
	});
