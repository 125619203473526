// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from "react";

export type ApplicationMenuContextProps = {
	/** */
	open: boolean;

	/** */
	setOpen?: React.Dispatch<React.SetStateAction<boolean>>;

	/** */
	item?: string;

	/** */
	setItem?: React.Dispatch<React.SetStateAction<string>>;
};

export const ApplicationMenuContext =
	React.createContext<ApplicationMenuContextProps>({
		open: false,
		setOpen: null,

		item: null,
		setItem: null,
	});
