export const getEnv = () => {
	let env: "development" | "testing" | "staging" | "production" = "development";

	if (/.*\.local$/.test(window.location.host)) {
		env = "development";
	} else if (/.*\.dev$/.test(window.location.host)) {
		env = "development";
		if (/^.*\.qa\./.test(window.location.host)) {
			env = "testing";
		}
		if (/^.*\.pp\./.test(window.location.host)) {
			env = "staging";
		}
	} else if (/.*\.(fr|cloud)$/.test(window.location.host)) {
		env = "production";
	}

	return env;
};
