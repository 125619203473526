// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import type { Reducer } from "redux";

import { UserLogoutType, UserProfileType } from "../../User/redux/types";
import { HistoryType } from "./types";

import { reduceAll } from "../../libs/redux/reducer";

const sort = ["module.identifiant", "date"];

// eslint-disable-next-line default-param-last, @typescript-eslint/default-param-last
export const historyReducer: Reducer = (state, action) => {
	switch (action.type) {
		case HistoryType:
			return reduceAll(state, action.history, sort);

		case UserLogoutType:
		case UserProfileType:
			return [];

		default:
			return state || [];
	}
};
