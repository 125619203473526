// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

/* eslint-disable */

import * as _ from "lodash";

export const buildEndPoint = (
	endpoint: string,
	params?: { [key: string]: any },
): string => {
	const queryString = _.compact(
		_.reduce(
			params,
			(results, value, name) => [
				...results,
				!_.isNil(value) && `${name}=${value}`,
			],
			[],
		),
	);

	return `${endpoint}${!_.isEmpty(queryString) ? `?${queryString.join("&")}` : ""}`;
};
