// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from "lodash";

import { createSelector } from "reselect";

const getState = (state) => state.domains;
const getId = (state, identifiant) => identifiant;

const getDomains = (domains) => domains;
const getDomainById = (domains, identifiant) =>
	_.find(domains, (d) => d.identifiant === identifiant);

const selectorDomains = createSelector(getState, getDomains);
const selectorDomainById = createSelector([getState, getId], getDomainById);

export const domainSelector = (identifiant: string | number) => (state) =>
	selectorDomainById(state, identifiant);

export const domainsSelector = () => (state) => selectorDomains(state);
