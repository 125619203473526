// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

export * from "./ApplicationFrame";
export * from "./ApplicationFrameContext";

// eslint-disable-next-line no-restricted-exports
export { ApplicationFrame as default } from "./ApplicationFrame";
