// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from "lodash";

import Axios from "axios";

import * as host from "./host";

type Assets = { files?: string[]; entrypoints?: { [key: string]: string[] } };

const registry: Record<string, Assets> = {};

const ensureContainer = (id: string) => {
	let container = document.querySelector(`#module > #${id}`);

	if (!container) {
		container = document.createElement("div");
		container.id = id;
		container.className = id;

		const element = document.getElementById("module");
		element?.append(container);
	}

	return container;
};

const enableAssets = (id: string) => {
	const element = document.querySelector(`#module > #${id}`);

	if (element) {
		for (const node of element.childNodes) {
			if (node.nodeName === "LINK") {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				node.removeAttribute("disabled");
			}
		}
	}
};

const disableAssets = (id: string) => {
	const element = document.querySelector(`#module > #${id}`);

	if (element) {
		for (const node of element.childNodes) {
			if (node.nodeName === "LINK") {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				node.setAttribute("disabled", "true");
			}
		}
	}
};

const injectAssets = (id: string, hostname: string, assets: Assets) =>
	new Promise((resolve, reject) => {
		if (_.isEmpty(assets?.files)) {
			return;
		}

		const container = ensureContainer(id);

		if (assets.entrypoints?.[".js"]) {
			for (const filename of assets.entrypoints?.[".js"] || []) {
				if (
					!document.querySelector(
						`#module > #${id} > script[src="${host.app(hostname)}/${filename}"]`,
					)
				) {
					const script = document.createElement("script");
					script.crossOrigin = "";
					script.type = "module";
					script.src = `${host.app(hostname)}/${filename}`;
					script.onload = () => {
						// eslint-disable-next-line no-underscore-dangle
						if (window.__modules__?.[id]) {
							// eslint-disable-next-line no-underscore-dangle
							resolve(window.__modules__[id]);
							return;
						}

						reject();
					};
					script.onerror = () => {
						reject();
					};
					container.appendChild(script);
				}
			}
		}

		if (assets.entrypoints?.[".css"]) {
			for (const filename of assets.entrypoints?.[".css"] || []) {
				if (
					!document.querySelector(
						`#module > #${id} > link[href="${host.app(hostname)}/${filename}"]`,
					)
				) {
					const link = document.createElement("link");
					link.rel = "stylesheet";
					link.type = "text/css";
					link.href = `${host.app(hostname)}/${filename}`;
					container.appendChild(link);
				}
			}
		}

		enableAssets(id);

		// eslint-disable-next-line no-underscore-dangle
		if (window.__modules__?.[id]) {
			// eslint-disable-next-line no-underscore-dangle
			resolve(window.__modules__[id]);
		}
	});

const fetchJson = (hostname: string, filename: string) =>
	Axios.get(`${host.app(hostname)}/${filename}`).then(({ data }) => data);

export const loadManifest = async (id: string, hostname: string) => {
	if (!registry[id]) {
		let assets: Assets = {};

		try {
			assets = await fetchJson(hostname, "manifest.json");
		} catch {
			assets = await fetchJson(hostname, "assets.json");
		}

		registry[id] = assets;
	}

	return injectAssets(id, hostname, registry[id]);
};

export const unloadManifest = async (id: string) => {
	disableAssets(id);
};
