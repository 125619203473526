// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

export const userTenants = "Collectivités";
export const userComponents = "Base de données";

export const userTenantSelect = "Sélectionner la collectivé";
export const userComponentSelect = "Sélectionner la base";

export const userTenantCancel = "Annuler";
export const userComponentCancel = "Annuler";

export const userTenantNoResult = "Vous n'avez acces a aucune collectivité";
export const userComponentNoResult =
	"Vous n'avez acces a aucune base de données";

export const userAbout = "A propos";
export const userSettings = "Mon compte";

export const userList = "Liste des utilisateurs";
export const userEmpty = "Aucun utilisateur";
