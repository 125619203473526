// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from "lodash";

import * as api from "../../../config/api";

import type { Module } from "../../Module";
import type { History } from "../History";

import * as redux from "../redux";

import { privateApi } from "../../../api";

import { useQuery } from "../../libs/hooks/useQuery";
import { Service } from "../../libs/services/Service";

import toDate from "../../utils/toDate";
import toRelativeDate from "../../utils/toRelativeDate";
import toTextualDate from "../../utils/toTextualDate";

export class HistoryService extends Service {
	static async getHistory(
		parent?: { identifiantModule?: string | number },
		args?: Record<string, unknown>,
	): Promise<History[]> {
		try {
			let endPoint = api.compilePath(api.historyEndPoint);
			let params = api.compileArgs(api.historyEndPoint, args);

			try {
				endPoint = api.compilePath(api.historyByModuleEndPoint, parent);
				params = api.compileArgs(api.historyByModuleEndPoint, args);
			} catch {
				endPoint = api.compilePath(api.historyEndPoint);
				params = api.compileArgs(api.historyEndPoint, args);
			}

			const result = (await privateApi.get<History>(
				endPoint,
				params,
			)) as History[];

			HistoryService.dispatch(redux.addHistoryAction(result));

			return result;
		} catch (e) {
			return [];
		}
	}

	static queryHistoryByModule(
		module: Module,
	): [{ [date: string]: History[] }, boolean, boolean] {
		const history = HistoryService.select<{ history: History[] }, History[]>(
			redux.historyByModuleSelector(module),
		);

		const [loading, fetching] = useQuery(
			() =>
				HistoryService.getHistory({ identifiantModule: module?.identifiant }),
			[module?.identifiant],
			_.isEmpty(history),
		);

		const reducer = (data: History[]): { [date: string]: History[] } =>
			_.reduce(
				_.groupBy(_.orderBy(data, "date").reverse(), "date"),
				(result, value, date) => {
					const textual = toTextualDate(date);

					if (result[textual]) {
						return {
							...result,
							[textual]: _.orderBy(
								[
									...result[textual],
									...value.map((h) => ({
										...h,
										date: toRelativeDate(toDate(h.date)),
									})),
								],
								"date",
							).reverse(),
						};
					}

					return {
						...result,
						[textual]: value.map((h) => ({
							...h,
							date: toRelativeDate(toDate(h.date)),
						})),
					};
				},
				{},
			);

		return [reducer(history), loading, fetching];
	}
}
