// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from "lodash";

import type { Module } from "../../Module";
import type { User } from "../../User";
import type { Menu } from "../Menu";

import * as redux from "../redux";

import { Service } from "../../libs/services/Service";

export class MenuService extends Service {
	static async addMenuByManifest(
		manifest: { 
			identifiant: string | number; 
			menus: Partial<{ 
				id: string, 
				code: string,
				name: string,
				path: string,
				exact?: boolean,
				parent?: string, 
				onChanged?: (user: User) => boolean 
			}>[] 
		},
		user: User,
	): Promise<Menu[]> {
		try {
			let result = [];

			const { identifiant, menus } = manifest;

			if (menus) {
				result = _.map(
					_.filter(
						_.filter(menus, (menu) => !menu.onChanged || menu.onChanged(user)),
						(menu, i, m) => !menu.parent || _.find(m, { parent: menu.parent }),
					),
					(menu) => ({
						identifiant: menu.id,
						identifiantParent: menu.parent,
						code: menu.code,
						libelle: menu.name,
						icone: menu.icon,
						adresse: menu.path,
						exact: menu.exact,
						module: { identifiant },
					}),
				) as Menu[];

				MenuService.dispatch(redux.addMenuAction(result));
			}

			return result;
		} catch (e) {
			return [];
		}
	}

	static queryMenuByModule(module: Module): [Menu[], boolean, boolean] {
		const menu = MenuService.select<{ menu: Menu[] }, Menu[]>(
			redux.menuByModuleSelector(module),
		);

		const reducer = (data: Menu[]) => {
			const nodes = {};

			return _.filter(
				_.orderBy(data, ["identifiantParent"], ["desc"]),
				(obj): boolean => {
					const id = obj.identifiant;
					const parentId = obj.identifiantParent;

					nodes[id] = _.defaults(obj, nodes[id], { items: [] });

					if (parentId) {
						const parentNode = nodes[parentId] || { items: [] };

						if (!parentNode.items.find((o) => o.identifiant === id)) {
							(nodes[parentId] = parentNode).items.push(obj);
						}
					}

					return !parentId;
				},
			);
		};

		return [reducer(menu), false, false];
	}
}
