// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import { createSelector } from "reselect";

const getState = (state) => state.user;

const getUser = (user) => user;

const selectorUser = createSelector(getState, getUser);

export const userSelector = () => (state) => selectorUser(state);
