// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import Axios, { type AxiosInstance } from "axios";
import * as _ from "lodash";

import { buildEndPoint } from "./buildEndPoint";

type PaginatedResponse<T> = {
	data: T[];
	meta: {
		currentPage: number;
		lastPage: number;
	};
};

type EndPointResponse<T> = PaginatedResponse<T> | T;

const isPaginated = <T>(
	value: EndPointResponse<T>,
): value is PaginatedResponse<T> => _.isArray(_.get(value, "data"));

export const factory = (axios: AxiosInstance) => {
	let cancelToken = Axios.CancelToken.source();

	return {
		defaults: axios.defaults,
		interceptors: axios.interceptors,
		cancel: () => {
			cancelToken.cancel();
			cancelToken = Axios.CancelToken.source();
		},
		get: <T>(
			endpoint: string,
			params?: Record<string, unknown>,
			config?: Record<string, unknown>,
			ttl = 60000,
		): Promise<T | T[]> =>
			new Promise<T | T[]>((resolve, reject) => {
				axios
					.get<EndPointResponse<T>>(buildEndPoint(endpoint, params), {
						...config,
						headers: {
							"Cache-Control": `Max-Age=${ttl}`,
							...((config?.headers as object) || {}),
						},
						cancelToken: cancelToken.token,
					})
					.then((params) => {
						const result = params?.data;

						if (!result) {
							resolve(null);
							return;
						}

						if (!isPaginated(result)) {
							resolve(result);
							return;
						}

						const {
							data,
							meta: { currentPage, lastPage },
						} = result;

						const requests: Promise<{ data: PaginatedResponse<T> }>[] = [];

						for (let page = currentPage + 1; page <= lastPage; page += 1) {
							requests.push(
								axios.get<PaginatedResponse<T>>(
									buildEndPoint(endpoint, { ...params, page }),
									{ cancelToken: cancelToken.token },
								),
							);
						}

						Promise.all(requests)
							.then((values) => {
								resolve([
									...data,
									..._.reduce(
										values,
										(results, { data: { data: value } }) => [
											...results,
											...value,
										],
										[],
									),
								]);
							})
							.catch((e) => {
								reject(e);
							});
					})
					.catch((e) => {
						reject(e);
					});
			}),
		post: <T>(
			endpoint: string,
			params?: Record<string, unknown>,
			config?: Record<string, unknown>,
		): Promise<T> =>
			new Promise<T>((resolve, reject) => {
				axios
					.post<T>(buildEndPoint(endpoint), params, {
						...config,
						cancelToken: cancelToken.token,
					})
					.then((params) => {
						const result = params?.data;

						resolve(result);
					})
					.catch((e) => {
						reject(e);
					});
			}),
		patch: <T>(
			endpoint: string,
			params?: Record<string, unknown>,
			config?: Record<string, unknown>,
		): Promise<T> =>
			new Promise<T>((resolve, reject) => {
				axios
					.patch<T>(buildEndPoint(endpoint), params, {
						...config,
						cancelToken: cancelToken.token,
					})
					.then((params) => {
						const result = params?.data;

						resolve(result);
					})
					.catch((e) => {
						reject(e);
					});
			}),
		put: <T>(
			endpoint: string,
			params?: Record<string, unknown>,
			config?: Record<string, unknown>,
		): Promise<T> =>
			new Promise<T>((resolve, reject) => {
				axios
					.put<T>(buildEndPoint(endpoint), params, {
						...config,
						cancelToken: cancelToken.token,
					})
					.then((params) => {
						const result = params?.data;

						resolve(result);
					})
					.catch((e) => {
						reject(e);
					});
			}),
		delete: <T>(
			endpoint: string,
			params?: Record<string, unknown>,
			config?: Record<string, unknown>,
		): Promise<T | T[]> =>
			new Promise<T | T[]>((resolve, reject) => {
				axios
					.delete<never>(buildEndPoint(endpoint, params), {
						...config,
						cancelToken: cancelToken.token,
					})
					.then((params) => {
						const result = params?.data;

						resolve(result);
					})
					.catch((e) => {
						reject(e);
					});
			}),
	};
};
