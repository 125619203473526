// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

export const favoriteEndPoint = "/api/v1/utilisateurs/favoris/:identifiant";
export const favoritesEndPoint = "/api/v1/utilisateurs/favoris";
export const favoritesReorderEndPoint = "/api/v1/utilisateurs/favoris/reorder";
export const favoritesByDomainEndPoint =
	"/api/v1/utilisateurs/produits/domaines/:identifiantDomaine/favoris";
export const favoritesByModuleEndPoint =
	"/api/v1/utilisateurs/produits/modules/:identifiantModule/favoris";
