// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import type { Reducer } from "redux";

import type { User } from "../User";

import {
	UserComponentType,
	UserLoginType,
	UserLogoutType,
	UserProfileType,
	UserRefreshType,
	UserTenantType,
} from "./types";

import { localStorage } from "../../../private";

// eslint-disable-next-line default-param-last, @typescript-eslint/default-param-last
export const userReducer: Reducer = (state, action) => {
	switch (action.type) {
		case UserLoginType:
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			return localStorage.save<User>("auth.user", action.user);

		case UserLogoutType:
			return null;

		case UserRefreshType:
			return localStorage.save<User>("auth.user", {
				...state,
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				...action.user,
			});

		case UserTenantType:
			return localStorage.save<User>("auth.user", {
				...state,
				tenant: action.tenant,
			});

		case UserComponentType:
			return localStorage.save<User>("auth.user", {
				...state,
				component: action.component,
			});

		case UserProfileType:
			return localStorage.save<User>("auth.user", {
				...state,
				profile: action.profile,
			});

		default:
			return state || localStorage.load<User>("auth.user");
	}
};
