// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

export const authenticationHeader = "Connexion";
export const authenticationSubHeader = "Bienvenue, veuillez vous authentifier";

export const authenticationLoading = "Connexion en cours ...";
export const authenticationFailed =
	"Le serveur d'authentification a rejeté votre demande.";
export const authenticationError =
	"Le serveur d'authentification est momentanément indisponible.";
export const authenticationUnknownError =
	"Une erreur est survenue lors de l'authentification.";

export const authenticationLabelIdentity = "Identifiant";
export const authenticationLabelPassword = "Mot de passe";

export const authenticationPlaceholderIdentity = "Identifiant";
export const authenticationPlaceholderPassword = "Mot de passe";

export const authenticationErrorIdentity = "L'identifiant est obligatoire.";
export const authenticationErrorPassword = "Le mot de passe est obligatoire.";

export const authenticationLogin = "Se connecter";
export const authenticationLogout = "Se déconnecter";

export const authenticationRecovery = "Mot de passe oublié ?";

export const authentificationTooMuch = (minutes) =>
	`Trop de tentatives de connexion ont échouée.\nMerci de réessayer de vous connecter dans ${minutes > 0 ? `${minutes} minutes` : "quelques instants"}.`;
