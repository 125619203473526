/**
 * Gets the JWT Token Id from a full JWT.
 */
const getJwtId = (jwt: string) => {
	const payload = jwt.split(".")[1];

	const data = atob(payload);

	return JSON.parse(data).jti;
};

export default getJwtId;
