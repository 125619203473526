// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from "lodash";

import { createSelector } from "reselect";

import type { Domain } from "../../Domain";
import type { Module } from "../../Module";

const getState = (state) => state.favorites;
const getModules = (state) => state.modules;

const getDomain = (favorites, modules, domain) => domain;
const getModule = (favorites, module) => module;
const getCode = (favorites, module, code) => code;
const getFavoriteByDomain = (favorites, modules, domain) =>
	_.filter(favorites, (f) =>
		_.isEqual(
			_.get(
				_.find(modules, (m) => m.identifiant === f.module.identifiant),
				"domaine.identifiant",
			),
			domain?.identifiant,
		),
	);
const getFavoriteByModule = (favorites, module) =>
	_.filter(favorites, (f) =>
		_.isEqual(_.get(f.module, "identifiant"), module?.identifiant),
	);
const getFavoriteByCode = (favorites, module, code) =>
	_.some(
		favorites,
		(f) =>
			_.isEqual(_.get(f.module, "identifiant"), module?.identifiant) &&
			_.isEqual(f.code, code),
	);

const selectorFavoritesByDomain = createSelector(
	[getState, getModules, getDomain],
	getFavoriteByDomain,
);
const selectorFavoritesByModule = createSelector(
	[getState, getModule],
	getFavoriteByModule,
);

const selectorFavoritesByCode = createSelector(
	[getState, getModule, getCode],
	getFavoriteByCode,
);

export const favoritesByDomainSelector = (domain: Domain) => (state) =>
	selectorFavoritesByDomain(state, state, domain);

export const favoritesByModuleSelector = (module: Module) => (state) =>
	selectorFavoritesByModule(state, module);

export const favoriteByCodeSelector =
	(code: string, module: Module) => (state) =>
		selectorFavoritesByCode(state, module, code);
