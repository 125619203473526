// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from "lodash";

import * as api from "../../../config/api";

import type { Domain } from "../Domain";

import * as redux from "../redux";

import { privateApi } from "../../../api";

import { useQuery } from "../../libs/hooks/useQuery";
import { Service } from "../../libs/services/Service";

export class DomainService extends Service {
	static async getDomains(args?: Record<string, unknown>): Promise<Domain[]> {
		try {
			const result = (await privateApi.get<Domain>(
				api.compilePath(api.domainsEndPoint),
				api.compileArgs(api.domainsEndPoint, args),
			)) as Domain[];

			DomainService.dispatch(redux.addDomainsAction(result));

			return result;
		} catch (e) {
			return [];
		}
	}

	static async getDomain(
		identifiant: string | number,
		args?: Record<string, unknown>,
	): Promise<Domain> {
		try {
			const result = (await privateApi.get<Domain>(
				api.compilePath(api.domainEndPoint, { identifiant }),
				api.compileArgs(api.domainEndPoint, args),
			)) as Domain;

			DomainService.dispatch(redux.addDomainAction(result));

			return result;
		} catch (e) {
			return null;
		}
	}

	static queryDomain(identifiant: string | number): [Domain, boolean, boolean] {
		const domain = DomainService.select<{ domains: Domain[] }, Domain>(
			redux.domainSelector(identifiant),
		);

		const [loading, fetching] = useQuery(
			async () => {
				if (identifiant) {
					await DomainService.getDomain(identifiant);
				}

				return Promise.resolve();
			},
			[identifiant],
			_.isNil(domain),
		);

		return [domain, loading, fetching];
	}

	static queryDomains(): [Domain[], boolean, boolean] {
		const domains = DomainService.select<{ domains: Domain[] }, Domain[]>(
			redux.domainsSelector(),
		);

		const [loading, fetching] = useQuery(
			() => DomainService.getDomains(),
			[],
			_.isEmpty(domains),
		);

		return [domains, loading, fetching];
	}
}
