// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from "lodash";

import type { Module } from "../../Module";
import type { User } from "../../User";
import type { SpeedDial } from "../SpeedDial";

import * as redux from "../redux";

import { Service } from "../../libs/services/Service";

export class SpeedDialService extends Service {
	static async addSpeedDialByManifest(
		manifest: {
			identifiant: string | number;
			speedDial?: Partial<{ id: string; name: string; buttons: [] }>;
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		user: User,
	): Promise<SpeedDial> {
		try {
			let result = {} as SpeedDial;

			const { identifiant, speedDial } = manifest;

			if (speedDial) {
				result = {
					identifiant: speedDial.id,
					libelle: speedDial.name,
					buttons: _.map(speedDial.buttons, (button) => ({
						identifiant: button.id,
						libelle: button.name,
						titre: button.title,
						icone: button.icon,
						adresse: button.path,
					})),
					module: { identifiant },
				};

				SpeedDialService.dispatch(redux.addSpeedDialAction(result));
			}

			return result;
		} catch (e) {
			return null;
		}
	}

	static querySpeedDialByModule(
		module: Module,
	): [SpeedDial[], boolean, boolean] {
		const speedDial = SpeedDialService.select<
			{ speedDials: SpeedDial[] },
			SpeedDial[]
		>(redux.speedDialByModule(module));

		return [speedDial, false, false];
	}
}
