// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

export const notificationEndPoint =
	"/api/v1/utilisateurs/notifications/:identifiant";
export const notificationsEndPoint = "/api/v1/utilisateurs/notifications";
export const notificationSubscribeEndPoint =
	"/api/v1/utilisateurs/notifications/stream?bearer=:bearer";
