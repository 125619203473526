// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import { decrypt, encrypt } from "../utils/crypt";

const secret = new WeakMap();

export type EncryptedStorageInterface = Storage;

export class EncryptedStorage implements EncryptedStorageInterface {
	private readonly storage: Storage;

	private keys: string[] = [];

	constructor(key: string, storage: Storage) {
		secret.set(this, key);

		this.storage = storage;

		this.initialize();
	}

	private initialize() {
		let values: { [x: string]: string | null | undefined } = {};

		// eslint-disable-next-line no-plusplus
		for (let index = 0; index < this.length; index++) {
			const key = this.key(index) as string;

			values = {
				[key]: this.getItem(key),
				...values,
			};
		}

		// eslint-disable-next-line no-plusplus
		for (let index = 0; index < this.length; index++) {
			const key = this.key(index) as string;

			this.setItem(key, values[key]);
		}
	}

	public get length() {
		return this.keys.length || 0;
	}

	public async setItem(key: string, value: unknown): Promise<void> {
		// eslint-disable-next-line no-nested-ternary
		const encryptedValue = encrypt(value, secret.get(this));

		this.storage.setItem(key, encryptedValue);

		this.addKey(key);
	}

	public getItem<T = unknown>(key: string): T {
		const item = this.storage.getItem(key);

		if (item) {
			this.addKey(key);

			try {
				return decrypt(item, secret.get(this)) as T;
			} catch {
				return null as T;
			}
		}

		return undefined as T;
	}

	public removeItem(key: string): void {
		this.storage.removeItem(key);

		this.delKey(key);
	}

	public clear(): void {
		this.storage.clear();
		this.keys = [];
	}

	public load<T>(key: string): T | null {
		try {
			return JSON.parse(this.getItem(key)) || null;
		} catch (e) {
			return null;
		}
	}

	public save<T>(key: string, value: T): T {
		try {
			this.setItem(key, JSON.stringify(value));
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e);
		}

		return value;
	}

	public key(index: number): string | null {
		return this.keys[index] || null;
	}

	private addKey(key: string): void {
		if (!this.keys.find((k) => k === key)) {
			this.keys.push(key);
		}
	}

	private delKey(key: string): void {
		const index = this.keys.findIndex((k) => k === key);
		if (index) {
			delete this.keys[index];
		}
	}
}
