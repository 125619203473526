// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

export * from "./ApplicationPage";
export * from "./ApplicationPageContext";

// eslint-disable-next-line no-restricted-exports
export { ApplicationPage as default } from "./ApplicationPage";
