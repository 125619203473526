// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from "react";

export type ApplicationHelpContextProps = {
	/** */
	open: boolean;

	/** */
	setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

// eslint-disable-next-line max-len
export const ApplicationHelpContext =
	React.createContext<ApplicationHelpContextProps>({
		open: false,
		setOpen: null,
	});
