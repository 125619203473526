// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import type { Feature } from "../Feature";

import { FeaturesType } from "./types";

export const addFeaturesAction = (features: Feature[]) => ({
	type: FeaturesType,
	features,
});
