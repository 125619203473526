// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import { EncryptedStorage } from "./EncryptedStorage";

const localStorage = new EncryptedStorage(
	window.location.hostname,
	window.localStorage,
);
const sessionStorage = new EncryptedStorage(
	window.location.hostname,
	window.sessionStorage,
);

export { localStorage, sessionStorage };
