// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from "lodash";

import { createSelector } from "reselect";

import type { User } from "../../User";

const getState = (state) => state.notifications;

const getUserIdentifiant = (notifications, user) => user?.profile.identifiant;
const getNotificationsByUser = (notifications, identifiant) =>
	_.filter(
		notifications,
		(n) =>
			n.type === "alerte" ||
			_.some(n.utilisateurs, (u) => u.identifiant === identifiant),
	);

const selectorNotificationsByUser = createSelector(
	[getState, getUserIdentifiant],
	getNotificationsByUser,
);

export const notificationsByUserSelector = (user: User) => (state) =>
	selectorNotificationsByUser(state, user);
