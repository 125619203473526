// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from "lodash";

import * as api from "../../../config/api";

import type { Domain } from "../../Domain";
import type { Module } from "../Module";

import * as redux from "../redux";

import { privateApi } from "../../../api";

import { useQuery } from "../../libs/hooks/useQuery";
import { Service } from "../../libs/services/Service";

export class ModuleService extends Service {
	static async getModules(
		parent?: { identifiantDomaine?: string | number },
		args?: Record<string, unknown>,
	): Promise<Module[]> {
		try {
			let endPoint = api.compilePath(api.modulesEndPoint);
			let params = api.compileArgs(api.modulesEndPoint, args);

			try {
				endPoint = api.compilePath(api.modulesByDomainEndPoint, parent);
				params = api.compileArgs(api.modulesByDomainEndPoint, args);
			} catch {
				endPoint = api.compilePath(api.modulesEndPoint);
				params = api.compileArgs(api.modulesEndPoint, args);
			}

			const result = (await privateApi.get<Module>(
				endPoint,
				params,
			)) as Module[];

			result.forEach((produit) => {
				produit.modules.forEach((module) => {
					module.domaine = produit.domaine;
					module.serial = produit.serial;
				});
			});

			ModuleService.dispatch(redux.addModulesAction(result));

			return result;
		} catch (e) {
			return [];
		}
	}

	static async getModule(
		identifiant: string | number,
		args?: Record<string, unknown>,
	): Promise<Module> {
		try {
			const result = (await privateApi.get<Module>(
				api.compilePath(api.moduleEndPoint, { identifiant }),
				api.compileArgs(api.moduleEndPoint, args),
			)) as Module;

			result.modules.forEach((module) => {
				module.domaine = result.domaine;
				module.serial = result.serial;
			});

			ModuleService.dispatch(redux.addModuleAction(result));

			return result;
		} catch (e) {
			return null;
		}
	}

	static queryModule(identifiant: string | number): [Module, boolean, boolean] {
		const module = ModuleService.select<{ modules: Module[] }, Module>(
			redux.moduleSelector(identifiant),
		);

		const [loading, fetching] = useQuery(
			async () => {
				if (identifiant) {
					await ModuleService.getModule(identifiant);
				}

				return Promise.resolve();
			},
			[identifiant],
			_.isNil(module),
		);

		return [module, loading, fetching];
	}

	static queryModuleByPage(
		path: string,
		disabled?: boolean,
	): [Module, boolean, boolean] {
		const module = ModuleService.select<{ modules: Module[] }, Module>(
			redux.moduleByPageSelector(path),
		);

		const [loading, fetching] = useQuery(
			async () => {
				if (!disabled) {
					await ModuleService.getModules();
				}
			},
			[disabled],
			_.isNil(module),
		);

		return [module, loading, fetching];
	}

	static queryModulesByDomain = (
		domain: Domain,
	): [Module[], boolean, boolean] => {
		const modules = ModuleService.select<{ modules: Module[] }, Module[]>(
			redux.modulesByDomainSelector(domain),
		);

		const [loading, fetching] = useQuery(
			() =>
				ModuleService.getModules({ identifiantDomaine: domain?.identifiant }),
			[domain?.identifiant],
			_.isEmpty(modules),
		);

		return [modules, loading, fetching];
	};
}
