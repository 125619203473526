// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from "lodash";

const aborted = Symbol("aborted");

let responseStorage = {};
let requestStorage = {};

export const cache = {
	getUniqueUrl(config) {
		let { data } = config;

		if (!_.isString(data)) {
			data = JSON.stringify(data);
		}

		return `${config.method}:${config.url}=${data}`;
	},
	getTtl(config) {
		if (config.headers["Cache-Control"] === "no-cache") return 0;

		const regexp = /Max-Age=(\d*)/.exec(config.headers["Cache-Control"]);
		if (regexp) {
			return _.toNumber(regexp[1]);
		}

		return 1000;
	},
	isCached(config) {
		const uniqueUrl = this.getUniqueUrl(config);

		return responseStorage[uniqueUrl] !== undefined;
	},
	setCachedResponse(config, response) {
		const uniqueUrl = this.getUniqueUrl(config);
		const ttl = this.getTtl(config);

		if (ttl > 0) {
			responseStorage[uniqueUrl] = response;

			setTimeout(() => {
				delete responseStorage[uniqueUrl];
				delete requestStorage[uniqueUrl];
			}, ttl);
		}
	},
	getCachedResponse(config) {
		const getResponse = (cfg) => {
			const uniqueUrl = this.getUniqueUrl(cfg);

			if (responseStorage[uniqueUrl]) {
				return responseStorage[uniqueUrl];
			}

			if (!requestStorage[uniqueUrl]) {
				return aborted;
			}

			return null;
		};

		return new Promise((resolve, reject) => {
			const response = getResponse(config);

			if (response) {
				resolve(response);
				return;
			}

			if (response === aborted) {
				reject();
				return;
			}

			const interval = setInterval(() => {
				const res = getResponse(config);

				if (res) {
					clearInterval(interval);
					resolve(res);
				}

				if (res === aborted) {
					reject();
				}
			}, 100);
		});
	},
	isPending(config) {
		const uniqueUrl = this.getUniqueUrl(config);

		return requestStorage[uniqueUrl] !== undefined;
	},
	addPendingRequest(config) {
		const uniqueUrl = this.getUniqueUrl(config);

		requestStorage[uniqueUrl] = config;
	},
	delPendingRequest(config) {
		const uniqueUrl = this.getUniqueUrl(config);

		if (requestStorage[uniqueUrl]) {
			delete requestStorage[uniqueUrl];
		}
	},
	clear(config?) {
		if (config) {
			const uniqueUrl = this.getUniqueUrl(config);

			if (responseStorage[uniqueUrl]) {
				delete responseStorage[uniqueUrl];
			}
			if (requestStorage[uniqueUrl]) {
				delete requestStorage[uniqueUrl];
			}
		} else {
			responseStorage = {};
			requestStorage = {};
		}
	},
};
