// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

export const draftHeader = "Brouillons";
export const draftEmpty = "Aucun brouillon";

export const draftDelete = "Supprimer des brouillons";
