// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import type { Dashboard } from "../Dashboard";
import type { DashboardWidget } from "../DashboardWidget";

import {
	DashboardCurrentType,
	DashboardType,
	DashboardWidgetType,
	DashboardWidgetsType,
	DashboardsType,
} from "./types";

export const addDashboardsAction = (dashboards: Dashboard[]) => ({
	type: DashboardsType,
	dashboards,
});

export const addDashboardAction = (dashboard: Dashboard) => ({
	type: DashboardType,
	dashboard,
});

export const deleteDashboardAction = (identifiant: string | number) => ({
	type: DashboardType,
	identifiant,
});

export const setDashboardAction = (dashboard: Dashboard | number) => ({
	type: DashboardCurrentType,
	dashboard,
});

export const addWidgetAction = (
	dashboard: Dashboard | number,
	widget: DashboardWidget,
) => ({
	type: DashboardWidgetType,
	dashboard,
	widget,
});

export const deleteWidgetAction = (dashboard: Dashboard | number) => ({
	type: DashboardWidgetType,
	dashboard,
});

export const setWidgetsAction = (
	dashboard: Dashboard | number,
	widgets: DashboardWidget[],
) => ({
	type: DashboardWidgetsType,
	dashboard,
	widgets,
});
