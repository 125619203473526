// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from "lodash";

import { createSelector } from "reselect";

import { leven } from "@jvs-group/jvs-mairistem-tools";

import type { Domain } from "../../Domain";

const getState = (state) => state.modules;

const getId = (state, identifiant) => identifiant;
const getPath = (state, path) => path;
const getDomain = (state, domain) => domain;

const getModuleById = (modules, id) =>
	_.find(modules, (m) => m.identifiant === id);
const getModuleByPage = (modules, path) => {
	const equivalent = _.minBy(
		_.map(modules, (m) => ({
			identifiant: m.identifiant,
			leven: _.startsWith(path, m.adresse)
				? leven(m.adresse, path)
				: Number.POSITIVE_INFINITY,
		})),
		"leven",
	);

	const produit = _.find(
		modules,
		(m) => m.identifiant === equivalent.identifiant,
	);

	if (produit?.modules) {
		const equivalentModule = _.minBy(
			_.map(produit.modules, (m) => ({
				identifiant: m.identifiant,
				leven: _.startsWith(path, m.adresse)
					? leven(m.adresse, path)
					: Number.POSITIVE_INFINITY,
			})),
			"leven",
		);

		if (equivalent.leven > equivalentModule?.leven) {
			return _.find(
				produit.modules,
				(m) => m.identifiant === equivalentModule.identifiant,
			);
		}
	}

	return produit;
};
const getModulesByDomain = (modules, domain) =>
	_.filter(modules, (m) =>
		_.isEqual(_.get(m.domaine, "identifiant"), domain?.identifiant),
	);

const selectorModuleById = createSelector([getState, getId], getModuleById);
const selectorModuleByPage = createSelector(
	[getState, getPath],
	getModuleByPage,
);
const selectorModulesByDomain = createSelector(
	[getState, getDomain],
	getModulesByDomain,
);

export const moduleSelector = (identifiant: string | number) => (state) =>
	selectorModuleById(state, identifiant);

export const moduleByPageSelector = (path: string) => (state) =>
	selectorModuleByPage(state, path);

export const modulesByDomainSelector = (domain: Domain) => (state) =>
	selectorModulesByDomain(state, domain);
