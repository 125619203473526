// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from "lodash";

import { createSelector } from "reselect";

const getState = (state) => state.dashboards;

const getDashboards = (dashboards) => dashboards;
const findCurrent = (dashboards) => _.find(dashboards, (d) => d.current);

const selectorDashboards = createSelector(getState, getDashboards);
const selectorCurrentDashboard = createSelector(getState, findCurrent);

export const dashboardsSelector = () => (state) => selectorDashboards(state);

export const dashboardSelector = () => (state) =>
	selectorCurrentDashboard(state);
