// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from "react";

import type { Domain } from "../../entities/Domain";
import type { Feature } from "../../entities/Feature";
import type { Module } from "../../entities/Module";

type BreadcrumbPath = {
	// Finances, Administrés...
	domain: Domain;
	// Module sélectionné dans le domaine
	module: Module;
	// Point de menu sélectionné dans le module
	feature: Feature;
};

type BreadcrumbRenderers = {
	BreadcrumbContainer: React.ComponentType;
	BreadcrumbSeparator: React.ComponentType;
	BreadcrumbSection: React.ComponentType;
};

export type ApplicationPageContextProps = {
	/** */
	manifest: Record<string, unknown> & {
		components: Record<
			string,
			{
				name: string;
				mount: (container: HTMLElement, props: Record<string, unknown>) => void;
				unmount: (container?: HTMLElement) => void;
			}
		>;
		menus: Array<{ id: string; code: string; name: string; path: string }>;
		features: Array<{ id: string; name: string; path: string }>;
		// Fonction récupérer de l'App du développeur pour custom le fil d'arianne
		breadcrumbRenderer?: (
			currentPath: BreadcrumbPath,
			renderers: BreadcrumbRenderers,
		) => React.ReactNode;
	};

	/** */
	loading: boolean;

	/** */
	error: boolean;
};

export const ApplicationPageContext =
	React.createContext<ApplicationPageContextProps>({
		manifest: null,

		loading: false,

		error: false,
	});
