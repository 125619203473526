// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

/* eslint-disable */

import * as React from "react";

import { privateApi } from "../api";

export const usePrivateApi = <T>(
	uri: string,
	callback: (data: any) => T = (data) => data,
): [T, Error | null, boolean] => {
	const [data, setData] = React.useState<T>(null as T);
	const [error, setError] = React.useState(null);
	const [loading, setLoading] = React.useState(false);

	React.useEffect(() => {
		setLoading(true);

		privateApi
			.get(uri)
			.then((data) => {
				setData(callback(data));
			})
			.catch((error) => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [uri]);

	return [data, error, loading];
};
