// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import type { Reducer } from "redux";

import { UserLogoutType, UserProfileType } from "../../User/redux/types";
import { NotificationType, NotificationsType } from "./types";

import { reduceAll, reduceOne } from "../../libs/redux/reducer";

const sort = ["module.identifiant", "date"];

// eslint-disable-next-line default-param-last, @typescript-eslint/default-param-last
export const notificationsReducer: Reducer = (state, action) => {
	switch (action.type) {
		case NotificationsType:
			return action.notifications
				? reduceAll(state, action.notifications, sort)
				: [];

		case NotificationType:
			return reduceOne(
				state,
				action.notification,
				(notification) => notification.identifiant !== action.identifiant,
				sort,
			);

		case UserLogoutType:
		case UserProfileType:
			return [];

		default:
			return state || [];
	}
};
