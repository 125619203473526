// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from "lodash";
import type { Reducer } from "redux";

import { features } from "../data";

import { UserLogoutType, UserProfileType } from "../../User/redux/types";
import { FeatureType, FeaturesType } from "./types";

import { reduceAll, reduceOne } from "../../libs/redux/reducer";

const sort = ["module.identifiant", "adresse"];

const initialState = _.sortBy(features, sort);

// eslint-disable-next-line default-param-last, @typescript-eslint/default-param-last
export const featuresReducer: Reducer = (state, action) => {
	switch (action.type) {
		case FeaturesType:
			return reduceAll(state, action.features, sort);

		case FeatureType:
			return reduceOne(
				state,
				action.favorite,
				(feature) => feature.adresse !== action.adresse,
				sort,
			);

		case UserLogoutType:
		case UserProfileType:
			return initialState;

		default:
			return state || initialState;
	}
};
