// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from "lodash";

import * as api from "../../../config/api";

import type { User } from "../../User";
import type { Notification } from "../Notification";

import * as redux from "../redux";

import { privateApi } from "../../../api";

import { useQuery } from "../../libs/hooks/useQuery";
import { Service } from "../../libs/services/Service";

import toRelativeDate from "../../utils/toRelativeDate";

export class NotificationService extends Service {
	static async getNotifications(
		args?: Record<string, unknown>,
	): Promise<Notification[]> {
		try {
			const result = (await privateApi.get<Notification>(
				api.compilePath(api.notificationsEndPoint),
				api.compileArgs(api.notificationsEndPoint, args),
			)) as Notification[];

			NotificationService.dispatch(redux.addNotificationsAction(result));

			return result;
		} catch (e) {
			return [];
		}
	}

	static async viewNotification(
		identifiant: string | number,
		args?: Record<string, unknown>,
	): Promise<boolean> {
		try {
			const result = await privateApi.patch<Notification>(
				api.compilePath(api.notificationEndPoint, { identifiant }),
				api.compileArgs(api.notificationEndPoint, { ...args, vu: true }),
			);

			NotificationService.dispatch(redux.updateNotificationAction(result));

			return true;
		} catch (e) {
			return false;
		}
	}

	static async doneNotification(
		identifiant: string | number,
		args?: Record<string, unknown>,
	): Promise<boolean> {
		try {
			const result = await privateApi.patch<Notification>(
				api.compilePath(api.notificationEndPoint, { identifiant }),
				api.compileArgs(api.notificationEndPoint, { ...args, fait: true }),
			);

			NotificationService.dispatch(redux.updateNotificationAction(result));

			return true;
		} catch (e) {
			return false;
		}
	}

	static async addNotification(
		notification: Partial<Notification>,
		args?: Record<string, unknown>,
	): Promise<boolean> {
		try {
			const result = await privateApi.post<Notification>(
				api.compilePath(api.notificationsEndPoint),
				api.compileArgs(api.notificationEndPoint, { ...notification, ...args }),
			);

			NotificationService.dispatch(redux.addNotificationAction(result));

			return true;
		} catch (e) {
			return false;
		}
	}

	static async saveNotification(
		notification: Partial<Notification>,
		args?: Record<string, unknown>,
	): Promise<boolean> {
		try {
			const apiRoute = notification.identifiant
				? privateApi.patch
				: privateApi.post;
			const apiPath = notification.identifiant
				? api.compilePath(api.notificationEndPoint, notification)
				: api.compilePath(api.notificationsEndPoint);
			const apiArgs = api.compileArgs(api.notificationEndPoint, {
				...notification,
				...args,
			});

			const result = await apiRoute<Notification>(apiPath, apiArgs);

			NotificationService.dispatch(redux.updateNotificationAction(result));

			return true;
		} catch (e) {
			return false;
		}
	}

	static async removeNotification(
		identifiant: string | number,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		args?: Record<string, unknown>,
	): Promise<boolean> {
		try {
			NotificationService.dispatch(redux.deleteNotificationAction(identifiant));

			return true;
		} catch (e) {
			return false;
		}
	}

	static async deleteNotification(
		identifiant: string | number,
		args?: Record<string, unknown>,
	): Promise<boolean> {
		try {
			await privateApi.delete<Notification>(
				api.compilePath(api.notificationEndPoint, { identifiant }),
				api.compileArgs(api.notificationEndPoint, args),
			);

			NotificationService.dispatch(redux.deleteNotificationAction(identifiant));

			return true;
		} catch (e) {
			return false;
		}
	}

	static async clearNotifications(
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		args?: Record<string, unknown>,
	): Promise<boolean> {
		try {
			NotificationService.dispatch(redux.deleteNotificationsAction());

			return true;
		} catch (e) {
			return false;
		}
	}

	// const subscribeNotifications = (): (() => void) => {
	//   const storage = JSON.parse(localStorage.getItem('auth.user'));
	//   const bearer = storage.token.access;

	//   const events = new EventSource(
	//     api.compilePath(api.notificationSubscribeEndPoint, { bearer, poll: 60 }),
	//   );

	//   events.onmessage = (event) => {
	//     const result = JSON.parse(event.data);

	//     if (result.status === 200) {

	//     }
	//   };

	//   events.onerror = (e) => {
	//     // eslint-disable-next-line
	//     console.error(e);
	//     events.close();
	//   };

	//   return events.close;
	// };

	static queryNotifications(user: User): [Notification[], boolean, boolean] {
		const notifications = NotificationService.select<
			{ notifications: Notification[] },
			Notification[]
		>(redux.notificationsByUserSelector(user));

		const [loading, fetching] = useQuery(
			() => NotificationService.getNotifications(),
			[],
			_.isEmpty(notifications),
		);

		const reducer = (data: Notification[]) =>
			_.reduce(
				_.orderBy(data, "date").reverse(),
				(result, notification) => {
					const relative = toRelativeDate(notification.date);

					return [
						...result,
						{
							...notification,
							date: relative,
						},
					];
				},
				[],
			);

		return [reducer(notifications), loading, fetching];
	}

	static queryUnreadNotifications(
		user: User,
	): [Notification[], boolean, boolean] {
		const [notifications, loading, fetching] =
			NotificationService.queryNotifications(user);

		return [notifications.filter((n) => !n.vu), loading, fetching];
	}

	static validateNotification = (notification) => {
		const errors = [];

		if (notification.type === "tache") {
			if (_.isEmpty(notification.libelle)) {
				errors.push({
					id: "libelle",
					content: "Le libellé est obligatoire.",
				});
			}

			if (_.isEmpty(notification.utilisateurs)) {
				errors.push({
					id: "utilisateurs",
					content: "Au moins un utilisateur doit être renseigné.",
				});
			}
		}

		return errors;
	};
}
