// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from "lodash";

import { createSelector } from "reselect";

import type { Module } from "../../Module";

const getState = (state) => state.history;

const getModule = (history, module) => module;
const getHistoryByModule = (history, module) =>
	_.filter(history, (d) =>
		_.isEqual(_.get(d.module, "identifiant"), module?.identifiant),
	);

const selectorHistoryByModule = createSelector(
	[getState, getModule],
	getHistoryByModule,
);

export const historyByModuleSelector = (module: Module) => (state) =>
	selectorHistoryByModule(state, module);
