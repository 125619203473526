// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import { Provider, useSelector } from "react-redux";
import type { Store } from "redux";

export const ReduxProvider = Provider;

class StoreManager {
	private cache: Record<string, unknown> = {};

	private reduxStore?: Store = undefined;

	private static storeInstance: StoreManager;

	public static getInstance = () => {
		if (!StoreManager.storeInstance) {
			StoreManager.storeInstance = new StoreManager();
		}

		return StoreManager.storeInstance;
	};

	public set store(store: Store) {
		this.reduxStore = store;
	}

	public get store() {
		if (!this.reduxStore) {
			throw Error("Store not yet configured");
		}

		return this.reduxStore;
	}

	// eslint-disable-next-line class-methods-use-this
	public select<T, U>(selector: (state: T) => U) {
		return useSelector<T, U>(selector);
	}

	public isCached(key: string) {
		return !!this.cache[key];
	}

	public cached(key: string) {
		this.cache[key] = setTimeout(() => {
			delete this.cache[key];
		});
	}

	public clear() {
		this.cache = {};
	}
}

export const storeManager = StoreManager.getInstance();
