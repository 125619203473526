// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

export const menuHeader = "Menu";

export const menuLoading = "Chargement en cours ...";

export const menuHome = "Accueil";
export const menuSettings = "Paramètres";
