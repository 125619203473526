// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from "lodash";

import { createSelector } from "reselect";

import { matchPath } from "../../../services/router";

import { levenSearch } from "../../../utils/search";

const getState = (state) => state.features;

const getPage = (features, path) => path;
const getFeatureByPage = (features, path) =>
	_.find(
		features,
		(f) => !_.isEmpty(matchPath({ path: f.adresse, end: true }, path)),
	);

const getQuery = (features, query) => query;
const getFilter = (features, filter) => _.head(filter);
const getFeaturesByQuery = (features, query) =>
	_.filter(features, (f) => query.length && levenSearch(f.libelle, query));

const selectorFeatureByPage = createSelector(
	[getState, getPage],
	getFeatureByPage,
);

const selectorFeaturesByQuery = createSelector(
	[getState, getQuery, getFilter],
	getFeaturesByQuery,
);

export const featureByPageSelector = (path: string) => (state) =>
	selectorFeatureByPage(state, path);

export const featuresByQuerySelector = (query: string) => (state) =>
	selectorFeaturesByQuery(state, query);
