// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

/* eslint-disable */

import * as _ from "lodash";
import * as React from "react";

import { useEffect } from "@jvs-group/jvs-mairistem-tools";

export const useQuery = <T>(
	query: () => Promise<T> | undefined | null,
	dependencies: React.DependencyList,
	dependency: boolean,
): [boolean, boolean] => {
	const [loading, setLoading] = React.useState(false);
	const [fetching, setFetching] = React.useState(false);

	const ref = React.useRef(dependencies);

	useEffect(() => {
		const promise = query();

		if (promise) {
			setFetching(true);

			promise.finally(() => {
				setFetching(false);
			});
		}
	}, [dependencies]);

	useEffect(() => {
		setLoading(fetching && dependency);
	}, [fetching, dependency]);

	return [loading, fetching];
};
