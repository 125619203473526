// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import type { Reducer } from "redux";

import { UserLogoutType, UserProfileType } from "../../User/redux/types";
import { FavoriteType, FavoritesType } from "./types";

import { reduceAll, reduceOne } from "../../libs/redux/reducer";

const sort = ["module.identifiant", "groupe", "ordre"];

// eslint-disable-next-line default-param-last, @typescript-eslint/default-param-last
export const favoritesReducer: Reducer = (state, action) => {
	switch (action.type) {
		case FavoritesType:
			return reduceAll(state, action.favorites, sort);

		case FavoriteType:
			return reduceOne(
				state,
				action.favorite,
				(favorite) => favorite.identifiant !== action.identifiant,
				sort,
			);

		case UserLogoutType:
		case UserProfileType:
			return [];

		default:
			return state || [];
	}
};
