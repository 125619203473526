// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from "lodash";
import type { Reducer } from "redux";

import { domains } from "../data";

import { UserLogoutType, UserProfileType } from "../../User/redux/types";
import { DomainType, DomainsType } from "./types";

import { reduceAll, reduceOne } from "../../libs/redux/reducer";

const sort = ["ordre"];

const initialState = _.sortBy(domains, sort);

// eslint-disable-next-line default-param-last, @typescript-eslint/default-param-last
export const domainsReducer: Reducer = (state, action) => {
	switch (action.type) {
		case DomainsType:
			return reduceAll(state, action.domains, sort);

		case DomainType:
			return reduceOne(
				state,
				action.domain,
				(domain) => domain.identifiant !== action.identifiant,
				sort,
			);

		case UserLogoutType:
		case UserProfileType:
			return initialState;

		default:
			return state || initialState;
	}
};
