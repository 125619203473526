// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

export const errorHeader = "Il semblerait qu'une anomalie se soit produite.";
export const errorMessage =
	"\nNotre équipe a déjà été notifiée.\nNous faisons tout notre possible pour y remédier dans les plus brefs délais.\n\nMerci de votre compréhension.";
