// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from "lodash";

import { createSelector } from "reselect";

import type { Domain } from "../../Domain";

const getState = (state) => state.drafts;
const getModules = (state) => state.modules;

const getDomain = (drafts, modules, domain) => domain;
const getDraftsByDomain = (drafts, modules, domain) =>
	_.filter(drafts, (d) =>
		_.isEqual(
			_.get(
				_.find(modules, (m) => m.identifiant === d.module.identifiant),
				"domaine.identifiant",
			),
			domain?.identifiant,
		),
	);

const selectorDraftsByDomain = createSelector(
	[getState, getModules, getDomain],
	getDraftsByDomain,
);

export const draftsByDomainSelector = (domain: Domain) => (state) =>
	selectorDraftsByDomain(state, state, domain);
