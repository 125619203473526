// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

/* eslint-disable no-underscore-dangle */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import * as _ from "lodash";

export function disableReactDevTools() {
	if (!_.isObject(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
		return;
	}

	_.forEach(_.keys(window.__REACT_DEVTOOLS_GLOBAL_HOOK__), (prop) => {
		if (prop === "renderers") {
			window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = new Map();
		} else {
			window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = _.isFunction(
				window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop],
			)
				? Function.prototype
				: null;
		}
	});
}

export function disableReduxDevTools() {
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ = function compose() {
		return null;
	};
}
