// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

function parse(str) {
	const [date, time] = str.split(" ");
	return `${date}T${time}.000Z`;
}
const toDate = (date) => new Date(parse(date));

export default toDate;
