// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

/* eslint-disable */

import * as _ from "lodash";
import * as React from "react";

import { FavoriteService } from "../services";

type FavoriItemOrder = {
	/** Identifiant. */
	identifiant: string | number;
	/** Ordre. */
	ordre: number;
};

const useOrderedFavoris = <T extends FavoriItemOrder>(
	items: React.ReactElement<T>[],
): [
	React.ReactElement<T>[],
	React.Dispatch<React.SetStateAction<React.ReactElement<T>[]>>,
] => {
	const [orderedItems, setOrderedItems] = React.useState(items);

	const setItems: React.Dispatch<
		React.SetStateAction<React.ReactElement<T>[]>
	> = React.useCallback(
		(value: React.SetStateAction<React.ReactElement<T>[]>) => {
			// @ts-ignore
			const newItems: ItemOrdered<T>[] = _.isFunction(value)
				? (value as <U>(v: U[]) => U[])(orderedItems)
				: value;

			const newOrderedItems = [];

			newItems.map((item, index) => {
				const orderedIndex = orderedItems.findIndex(
					(i) => i.props.identifiant === item.props.identifiant,
				);

				if (index !== orderedIndex) {
					newOrderedItems.push({
						...item.props,
						ordre: index + 1,
					});

					return {
						...item,
						props: {
							...item.props,
							ordre: index + 1,
						},
					};
				}

				return item;
			});

			if (newOrderedItems.length) {
				FavoriteService.reorderFavorites(newOrderedItems);
			}

			setOrderedItems(newItems);
		},
		[orderedItems],
	);

	React.useEffect(() => {
		setOrderedItems(items);
	}, [items]);

	return [orderedItems, setItems];
};

export default useOrderedFavoris;
