// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

/* eslint-disable */

import classes from "classnames";
import * as _ from "lodash";

export const getApplicationClassName = (...args: string[]) =>
	classes(_.join(["application", ...args], "-"));

export const getClassName = (...args: any[]) => classes(...args);
