// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import type { Module } from "../Module";

export const modules: Module[] = [
	{
		identifiant: 0,
		code: "HOL_ENV",
		libelle: "Accueil",
		adresse: "/",
		repertoire: {
			api: "",
			app: "/transverse/accueil",
		},
		domaine: { identifiant: 0 },
	},
];
