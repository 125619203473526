// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import type { Feature } from "../Feature";

export const features: Feature[] = [
	{
		identifiant: 0,
		libelle: "Connexion",
		adresse: "/connexion",
		components: null,
		integre: true,
		module: { identifiant: 0 },
	},
	{
		identifiant: 1,
		libelle: "Tableau de bord",
		adresse: "/",
		components: {
			content: "Home",
			header: "HomeToolbar",
		},
		integre: true,
		module: { identifiant: 0 },
	},
	{
		identifiant: 2,
		libelle: "Parametres",
		adresse: "/parametres",
		components: {
			content: "Settings",
		},
		integre: true,
		module: { identifiant: 0 },
	},
];
