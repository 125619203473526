// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

export const domainEndPoint =
	"/api/v1/utilisateurs/produits/domaines/:identifiant";
export const domainsEndPoint = "/api/v1/utilisateurs/produits/domaines";
