// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from "react";

export const useToggle = (
	defaultValue?: boolean,
): [boolean, () => void, () => void] => {
	const [open, setOpen] = React.useState(defaultValue);

	const handleOpen = React.useCallback(() => {
		if (setOpen) {
			setOpen(true);
		}
	}, [setOpen]);

	const handleClose = React.useCallback(() => {
		if (setOpen) {
			setOpen(false);
		}
	}, [setOpen]);

	return [open, handleOpen, handleClose];
};

export default useToggle;
