// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import type { Menu } from "../Menu";

import { MenuType } from "./types";

export const addMenuAction = (menu: Menu[]) => ({
	type: MenuType,
	menu,
});
