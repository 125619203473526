// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from "lodash";

import * as api from "../../../config/api";

import type { Dashboard } from "../Dashboard";
import type { DashboardWidget } from "../DashboardWidget";

import * as redux from "../redux";

import { privateApi } from "../../../api";

import { useQuery } from "../../libs/hooks/useQuery";
import { Service } from "../../libs/services/Service";

const dashboardEndPoint = "/api/v1/utilisateurs/dashboards/:identifiant";
const dashboardWidgetsEndPoint =
	"/api/v1/utilisateurs/dashboards/:dashboard/widgets";
const dashboardsEndPoint = "/api/v1/utilisateurs/dashboards";

export class DashboardService extends Service {
	static async getDashboards(
		args?: Record<string, unknown>,
	): Promise<Dashboard[]> {
		try {
			const result = (await privateApi.get<Dashboard>(
				api.compilePath(dashboardsEndPoint),
				api.compileArgs(dashboardsEndPoint, args),
			)) as Dashboard[];

			// if (_.isEmpty(result)) {
			//   const newDashboard: Partial<Dashboard> = {
			//     nom: 'Tableau de bord',
			//   };

			//   await DashboardService.addDashboard(newDashboard);
			//   return await DashboardService.getDashboards();
			// }

			const dashboards = _.map(result, (d, i) => ({
				...d,
				current: i === 0,
			}));

			DashboardService.dispatch(redux.addDashboardsAction(dashboards));

			return result;
		} catch (e) {
			return [];
		}
	}

	static async addDashboard(
		dashboard: Partial<Dashboard>,
		args?: Record<string, unknown>,
	): Promise<boolean> {
		try {
			const result = await privateApi.post<Dashboard>(
				api.compilePath(dashboardsEndPoint),
				api.compileArgs(dashboardsEndPoint, { ...dashboard, ...args }),
			);

			DashboardService.dispatch(redux.addDashboardAction(result));

			return true;
		} catch (e) {
			return false;
		}
	}

	static async deleteDashboard(
		identifiant: string | number,
		args?: Record<string, unknown>,
	): Promise<boolean> {
		try {
			await privateApi.delete(
				api.compilePath(dashboardEndPoint, { identifiant }),
				api.compileArgs(dashboardEndPoint, args),
			);

			DashboardService.dispatch(redux.deleteDashboardAction(identifiant));

			return true;
		} catch (e) {
			return false;
		}
	}

	static async setCurrentDashboard(
		dashboard: Dashboard | number,
	): Promise<boolean> {
		try {
			DashboardService.dispatch(redux.setDashboardAction(dashboard));

			return true;
		} catch (e) {
			return false;
		}
	}

	static async addWidget(
		dashboard: Dashboard | number,
		widget: Partial<DashboardWidget>,
		args?: Record<string, unknown>,
	): Promise<boolean> {
		try {
			const result = await privateApi.post<DashboardWidget>(
				// eslint-disable-next-line max-len
				api.compilePath(dashboardWidgetsEndPoint, {
					dashboard: (dashboard as Dashboard)?.identifiant || dashboard,
				}),
				api.compileArgs(dashboardWidgetsEndPoint, { ...widget, ...args }),
			);

			DashboardService.dispatch(redux.addWidgetAction(dashboard, result));

			return true;
		} catch (e) {
			return false;
		}
	}

	static async deleteWidget(
		dashboard: Dashboard | number,
		widget: DashboardWidget,
		args?: Record<string, unknown>,
	): Promise<boolean> {
		try {
			await privateApi.delete(
				// eslint-disable-next-line max-len
				api.compilePath(dashboardWidgetsEndPoint, {
					dashboard: (dashboard as Dashboard)?.identifiant || dashboard,
				}),
				api.compileArgs(dashboardWidgetsEndPoint, { ...widget, ...args }),
			);

			DashboardService.dispatch(redux.deleteWidgetAction(dashboard));

			return true;
		} catch (e) {
			return false;
		}
	}

	static async reorderWidgets(
		dashboard: Dashboard | number,
		widgets: DashboardWidget[],
	): Promise<boolean> {
		try {
			const result = await privateApi.patch<DashboardWidget[]>(
				// eslint-disable-next-line max-len
				api.compilePath(dashboardWidgetsEndPoint, {
					dashboard: (dashboard as Dashboard)?.identifiant || dashboard,
				}),
				api.compileArgs(dashboardWidgetsEndPoint, widgets),
			);

			DashboardService.dispatch(redux.setWidgetsAction(dashboard, result));

			return true;
		} catch (e) {
			return false;
		}
	}

	static queryDashboards(): [Dashboard[], boolean, boolean] {
		const dashboards = DashboardService.select<
			{ dashboards: Dashboard[] },
			Dashboard[]
		>(redux.dashboardsSelector());

		const [loading, fetching] = useQuery(
			() => DashboardService.getDashboards(),
			[],
			_.isEmpty(dashboards),
		);

		return [dashboards, loading, fetching];
	}

	static queryCurrentDashboard(): [Dashboard, boolean, boolean] {
		const dashboard = DashboardService.select<
			{ dashboards: Dashboard[] },
			Dashboard
		>(redux.dashboardSelector());

		const [loading, fetching] = useQuery(
			() => DashboardService.getDashboards(),
			[],
			_.isNil(dashboard),
		);

		return [dashboard, loading, fetching];
	}
}
