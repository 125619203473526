// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import "./Dashboard.less";

export { default as Dashboard } from "./Dashboard";
export { default as DashboardBar } from "./DashboardBar";
