// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from "lodash";

// eslint-disable-next-line import/no-extraneous-dependencies
import * as pathToRegexp from "path-to-regexp";

export * from "./auth";
export * from "./tenant";

export * from "./domain";
export * from "./module";

export * from "./draft";
export * from "./favorite";
export * from "./history";

export * from "./user";

export * from "./notification";

export * from "./search";

const compile = {};
const parse = {};

export function parsePath(path) {
	if (parse[path]) return parse[path];

	let tokens = [];
	pathToRegexp.pathToRegexp(path, tokens);
	tokens = _.map(tokens, (t) => t.name);
	parse[path] = tokens;

	return tokens;
}

export function compilePath(path, params = {}) {
	if (compile[path]) return compile[path](params);

	const generator = pathToRegexp.compile(path);
	compile[path] = generator;

	return generator(params);
}

export function compileArgs(path, args = {}) {
	return _.omit(args, parsePath(path));
}
