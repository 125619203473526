// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

export const draftEndPoint = "/api/v1/utilisateurs/brouillons/:identifiant";
export const draftsEndPoint = "/api/v1/utilisateurs/brouillons";
export const draftsByDomainEndPoint =
	"/api/v1/utilisateurs/produits/domaines/:identifiantDomaine/brouillons";
export const draftsByModuleEndPoint =
	"/api/v1/utilisateurs/produits/modules/:identifiantModule/brouillons";
