// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from "lodash";

import * as api from "../../../config/api";

import type { Component } from "../Component";
import type { Profile } from "../Profile";
import type { Tenant } from "../Tenant";
import type { User } from "../User";

import * as redux from "../redux";

import { clear, privateApi, publicApi } from "../../../api";
import getJwtId from "../../../utils/jwt";

import { storeManager } from "../../../services/redux";
import { Service } from "../../libs/services/Service";

export class UserService extends Service {
	private static isRefreshing = false;
	private static refreshPromise: Promise<string> | null = null;

	private static resetable: Record<string, () => void> = {};

	static addResetable(key: string, resetable: () => void): void {
		UserService.resetable[key] = resetable;
	}

	static async login(
		username: string,
		password: string,
		args?: Record<string, unknown>,
	): Promise<boolean> {
		try {
			const auth = await publicApi.post<{
				token_type: string;
				access_token: string;
				refresh_token: string;
			}>(
				api.compilePath(api.authTokenEndPoint),
				api.compileArgs(api.authTokenEndPoint, {
					grant_type: "password",
					client_id: api.clientId,
					client_secret: api.clientSecret,
					username,
					password,
					scope: "*",
					...args,
				}),
				{ headers: { "Cache-Control": "no-cache" } },
			);

			const user = (await publicApi.get<User>(
				api.compilePath(api.authAccountEndPoint),
				api.compileArgs(api.authAccountEndPoint),
				{
					headers: {
						Authorization: `${auth.token_type} ${auth.access_token}`,
						"Cache-Control": "no-cache",
					},
				},
			)) as User;

			UserService.dispatch(
				redux.loginAction({
					...user,
					token: {
						access: auth.access_token,
						refresh: auth.refresh_token,
					},
				}),
			);

			return true;
		} catch (e) {
			// eslint-disable-next-line no-throw-literal, @typescript-eslint/no-throw-literal
			throw {
				status: e.response?.status,
				code: e.response?.data?.error.code,
				message: e.response?.data?.error.message,
			};
		}
	}

	static async logout(
		user: User,
		args?: Record<string, unknown>,
	): Promise<void> {
		try {
			await privateApi.delete<User>(
				api.compilePath(api.authTokensEndPoint, {
					tokenid: getJwtId(user.token.access),
				}),
				api.compileArgs(api.authTokensEndPoint, { ...args }),
				{ headers: { "Cache-Control": "no-cache" } },
			);
		} catch (e) {
			// console.warn(e);
		} finally {
			await UserService.clear();
		}
	}

	static async refresh(
		user: User,
		args?: Record<string, unknown>,
	): Promise<string> {
		if (UserService.isRefreshing) {
			return UserService.refreshPromise!;
		}

		UserService.isRefreshing = true;
		UserService.refreshPromise = new Promise(async (resolve, reject) => {
			try {
				const result = await publicApi.post<{
					token_type: string;
					access_token: string;
					refresh_token: string;
				}>(
					api.compilePath(api.authTokenEndPoint),
					api.compileArgs(api.authTokenEndPoint, {
						grant_type: "refresh_token",
						client_id: api.clientId,
						client_secret: api.clientSecret,
						refresh_token: user.token.refresh,
						...args,
					}),
					{ headers: { "Cache-Control": "no-cache" } },
				);

				UserService.dispatch(
					redux.refreshAction({
						...user,
						token: {
							access: result.access_token,
							refresh: result.refresh_token,
						},
					}),
				);

				resolve(result.access_token);
			} catch (e) {
				await UserService.clear();

				reject(new Error("Failed to refresh token"));
			} finally {
				UserService.isRefreshing = false;
				UserService.refreshPromise = null;
			}
		});

		return UserService.refreshPromise;
	}

	static async clear(): Promise<void> {
		privateApi.cancel();
		privateApi.defaults.headers.Authorization = null;
		privateApi.defaults.headers["X-Tenant"] = null;
		privateApi.defaults.headers["X-Component"] = null;

		UserService.dispatch(redux.logoutAction());

		clear();
		storeManager.clear();
		document.cookie = "tenant=; path=/; samesite=strict; secure";
		document.cookie = "component=; path=/; samesite=strict; secure";

		_.forEach(UserService.resetable, (resetable) => resetable());
	}

	static async tenant(user: User, tenant: Tenant): Promise<Tenant> {
		UserService.dispatch(redux.tenantAction(user, tenant));

		document.cookie = `tenant=${tenant.identifiant}; path=/; samesite=strict; secure`;

		return tenant;
	}

	static async component(user: User, component: Component): Promise<Component> {
		UserService.dispatch(redux.componentAction(user, component));

		document.cookie = `component=${component.identifiant}; path=/; samesite=strict; secure`;

		return component;
	}

	static async profile(
		user: User,
		tenant?: Tenant,
		component?: Component,
	): Promise<Profile> {
		clear();
		storeManager.clear();

		_.forEach(UserService.resetable, (resetable) => resetable());

		const profile = (await privateApi.get<Profile>(
			api.compilePath(api.authProfileEndPoint),
			api.compileArgs(api.authProfileEndPoint),
			{
				headers: {
					"Cache-Control": "no-cache",
					"X-Tenant": tenant?.identifiant,
					"X-Component": component?.identifiant,
				},
			},
		)) as Profile;

		UserService.dispatch(redux.profileAction(user, profile));

		return profile;
	}

	static queryUser(): [User, boolean, boolean] {
		const user = UserService.select<{ user: User }, User>(redux.userSelector());

		if (user) {
			privateApi.defaults.headers.Authorization = `Bearer ${user.token?.access}`;
			privateApi.defaults.headers["X-Tenant"] = user.tenant?.identifiant;
			privateApi.defaults.headers["X-Component"] = user.component?.identifiant;
		}

		return [user, false, false];
	}

	static isValid(user?: User): boolean {
		return !_.isNil(user) && _.has(user, "token");
	}
}
