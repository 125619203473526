// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

export const isUrl = (path: string) => {
	let url: URL;

	try {
		url = new URL(path);
	} catch (_) {
		return false;
	}

	return url.protocol === "http:" || url.protocol === "https:";
};

export const normalizePath = (path: string) => {
	const url = path?.replace(/\/{2,}/g, "/")?.replace(/\/$/, "");

	return url === "" ? "/" : url;
};
