// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from "lodash";

export const host = document.location.origin;

export const join = (
	hostname: string | Record<string, string>,
	path: string,
	separator: string,
) => {
	const fragment = _.isString(hostname) ? hostname : hostname?.[path];

	const hostReg = /^https?:\/\//i;

	return (hostReg.test(fragment) ? fragment : `/${path}/${fragment}`)
		.replace(/([^:]\/)\/+/g, "$1")
		.replace(new RegExp(`/${path}/${path}/`), `${separator}${path}/`);
};

export const api = (hostname?: string | Record<string, string>) =>
	join(hostname || "", "api", "!");

export const app = (hostname?: string | Record<string, string>) =>
	join(hostname || "", "app", "/");
