// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import toStringDate from "./toStringDate";

function format(number: number) {
	return String(number).padStart(2, "0");
}
const toRelativeDate = (date) => {
	const before = new Date(date);
	const now = new Date();

	const [beforeDate] = toStringDate(before);
	const [nowDate] = toStringDate(now);

	switch (true) {
		case beforeDate === nowDate:
			return `${format(before.getHours())}h${format(before.getMinutes())}`;

		case before.getFullYear() === now.getFullYear():
			return `${format(before.getDate())}/${format(before.getMonth())}`;

		default:
			return `${format(before.getDate())}/${format(before.getMonth())}/${before.getFullYear()}`;
	}
};

export default toRelativeDate;
